import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { FaArrowCircleRight } from "react-icons/fa"
import YearsIcon from "../images/svg/years.svg"
import ClientIcon from "../images/svg/client.svg"
import ManIcon from "../images/svg/man.svg"



const AboutPage = () => (
    < Layout >
        <Seo title="About" description="Established in 1997 with over 20 years of experience in IT integration and telecommunications." />
        <section className="about-page">
            <section className="about-team">
                <div className="about-image-wrapper">
                    <StaticImage src="../images/about/OurTeam.png" className="about-image" />
                </div>
                <div className="about-content-wrapper">
                    <div>
                        <h1>Our Team</h1>
                        <p>We are a company driven by excellence and customer responsiveness as we strive to always provide cost-effective and high-quality technological solutions that are delivered in a timely manner and with outstanding after-sales technical support. It is our continuing goal to build long-term relationships with our clients and business partners, and build a reputation for innovation, quality, and dependability.</p>

                    </div>
                    <div>
                        <h2 className="about-h2">Vision</h2>
                        <p className="about-text">Satisfying the world's needs through technology</p>
                    </div>
                    <div>
                        <h2 className="about-h2">Mission</h2>
                        <p className="about-text">Our mission is to design, create, and provide a tailor-fitted solution, ensuring that our clients' wants and needs are fully satisfied</p>
                    </div>
                </div>
            </section>
            <section>
                <div className="about-history">
                    <div className="about-image-wrapper">
                        <StaticImage src="../images/about/CompanyHistory.png" className="about-image" />
                    </div>
                    <div className="about-content-wrapper">
                        <div>
                            <h1>Company History</h1>
                            <div className="about-text"><p>Established in 1997 with over <strong>{new Date().getFullYear() - 1997} years of proven experience</strong> in IT integration and telecommunications.
                            </p><p>
                                    MARTINDALE TECHNOLOGIES INCORPORATED (MTI) is composed of highly competent personnel in the telecommunication and network solution industries that specialize in the survey, design, implementation, integration, optimization, and documentation of wired and wireless system requirements of today's telecom infrastructure and intelligent facilities and premises.</p>
                                {/* <ReactMarkdown source={about.CompanyHistory} className="about-text"></ReactMarkdown> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="highlight-section">
                <div className="highlight-wrapper">
                    <YearsIcon></YearsIcon>
                    <h4 className="about-highlights">
                        <span>{new Date().getFullYear() - 1997}<br /></span> Years in Service
                    </h4>
                </div>
                <div className="highlight-wrapper">
                    <ClientIcon></ClientIcon>
                    <h4 className="about-highlights">
                        <span>1000+ <br /></span> Completed Projects
                    </h4>
                </div>
                <div className="highlight-wrapper">
                    <ManIcon></ManIcon>
                    <h4 className="about-highlights">
                        <span>500+ </span> Teammates in three countries
                    </h4>
                </div>
            </section>
            <section>

                <div className="index-second contact-div">
                    <h2>Want to know more?</h2>
                    <Link to="/contact/" ><button className="btn">Shoot Us A Message
                        <FaArrowCircleRight className="btn-icon" /></button></Link>
                </div>
            </section>
        </section>
    </Layout >
)

export default AboutPage
